import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FancyDropdownContext from 'dpl/components/FancyDropdown/context';

export default function FancyDropdownMenu({
  children,
  className,
  contentClassName
}) {
  return (
    <FancyDropdownContext.Consumer>
      {({ isOpen, menuRef, positionAlignment, menuWidth }) => {
        if (!isOpen) {
          return null;
        }

        return (
          <div className="FancyDropdownMenu relative">
            <ul
              style={{ width: menuWidth }}
              className={classnames(
                'w-100 z-max br1 overscroll-contain',
                {
                  absolute: positionAlignment !== 'static',
                  'right-0': positionAlignment === 'right',
                  'left-0': positionAlignment === 'left'
                },
                className
              )}
              ref={menuRef}
            >
              <div className={classnames('bg-white', contentClassName)}>
                {children}
              </div>
            </ul>
          </div>
        );
      }}
    </FancyDropdownContext.Consumer>
  );
}

FancyDropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string
};

FancyDropdownMenu.defaultProps = {
  className: null,
  contentClassName: 'ph2 pv2'
};
