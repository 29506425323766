import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FancyDropdownContext from 'dpl/components/FancyDropdown/context';

export default function FancyDropdownToggle({ children, id, className }) {
  return (
    <FancyDropdownContext.Consumer>
      {({ toggleOpen }) => (
        <div className={classnames('FancyDropdownToggle', className)}>
          <button
            className="w-100 db"
            type="button"
            id={id}
            data-test-id={id}
            onClick={e => {
              // If the dropdown is inside of an anchor tag you need to preventDefault
              e.preventDefault();
              e.stopPropagation();
              toggleOpen();
            }}
          >
            {children}
          </button>
        </div>
      )}
    </FancyDropdownContext.Consumer>
  );
}

FancyDropdownToggle.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  className: PropTypes.string
};

FancyDropdownToggle.defaultProps = {
  id: 'FancyDropdownToggle',
  className: ''
};
