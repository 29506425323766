export default class CircularLinkedMap {
  constructor(...keyValues) {
    this.setInitialValues();
    keyValues.forEach(([key, value]) => this.set(key, value));
  }

  setInitialValues() {
    this.nodes = {};
    this.firstNode = null;
    this.lastNode = null;
    this.size = 0;
  }

  get isEmpty() {
    return this.size === 0;
  }

  set(key, value) {
    this.nodes[key] = { key, value };

    if (!this.firstNode) {
      this.firstNode = this.nodes[key];
    }
    if (!this.lastNode) {
      this.lastNode = this.nodes[key];
    }

    this.nodes[key].previous = this.lastNode;
    this.nodes[key].next = this.firstNode;

    // update previous and first nodes to point to us
    this.firstNode.previous = this.nodes[key];
    this.lastNode.next = this.nodes[key];

    // update last node to be us
    this.lastNode = this.nodes[key];
    this.size++;

    return true;
  }

  remove(key) {
    if (!(key in this.nodes)) {
      return false;
    }

    this.nodes[key].previous.next = this.nodes[key].next;
    this.nodes[key].next.previous = this.nodes[key].previous;

    if (this.lastNode === this.nodes[key]) {
      this.lastNode = this.nodes[key].previous;
    }

    if (this.firstNode === this.nodes[key]) {
      this.firstNode = this.nodes[key].next;
    }

    delete this.nodes[key];
    this.size--;

    if (this.isEmpty) {
      this.setInitialValues();
    }

    return true;
  }

  get(key) {
    return this.nodes[key];
  }
}
